import 'objectFitPolyfill';

const edgeVersion = window.navigator.userAgent.match(/Edge\/(\d{2})\./);
const edgeSupport = edgeVersion ? parseInt(edgeVersion[1], 10) >= 16 : false;
const hasSupport = 'objectFit' in document.documentElement.style !== false;

if (!hasSupport || edgeSupport) {
  document.querySelectorAll('.image--cover, .video--cover').forEach(($el) => {
    // Disable lazy-loading
    $el.querySelectorAll('noscript.loading-lazy').forEach(($noscript) => {
      const $content = document.createElement('div');
      $content.innerHTML = $noscript.textContent || $noscript.innerHTML;
      while ($content.firstChild) {
        $noscript.parentNode.insertBefore($content.firstChild, $noscript);
      }
      $noscript.parentNode.removeChild($noscript);
    });

    // Enable polyfill
    window.objectFitPolyfill($el.querySelectorAll('img, video'));
  });
}
